<template>
    <AppLayout v-if="isLoaded">
        <v-snackbar v-model="snackbarGeolocationCreated" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Geolocation Created</span>
            <v-btn text class="blue white--text" @click="snackbarGeolocationCreated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
         <v-snackbar v-model="snackbarGeolocationCreateFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Failed to create geolocation</span>
            <v-btn text class="red white--text" @click="snackbarGeolocationCreateFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-snackbar v-model="snackbarGeolocationDeleted" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Geolocation deleted</span>
            <v-btn text class="blue white--text" @click="snackbarGeolocationDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
         <v-snackbar v-model="snackbarGeolocationDeleteFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Failed to delete geolocation</span>
            <v-btn text class="red white--text" @click="snackbarGeolocationDeleteFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-row justify="center" class="py-5">
            <h1 class="text-h6 font-weight-light">Access Recovery</h1>
        </v-row>
        <v-row justify="center" class="pt-5">
            <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="pa-0">
                <v-card elevation="6" :class="{'py-5':true, 'px-10':$vuetify.breakpoint.smAndUp, 'px-6':$vuetify.breakpoint.xsOnly}">
                    <v-card-text class="text-h6 text-center">Secrets</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <!-- TODO: we can show whether they DID set a password, birthdate, or state id,  they just can't change it from here -->
                    <v-row justify="center" class="py-3 px-5">
                        <p class="text-body-1 font-weight-light text-center">Open the settings in the LoginShield app to manage access recovery secrets such as memorable date, state id, and password.</p>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-5">
            <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="pa-0">
                <v-card elevation="6" :class="{'py-5':true, 'px-10':$vuetify.breakpoint.smAndUp, 'px-6':$vuetify.breakpoint.xsOnly}">
                    <v-card-text class="text-h6 text-center">Geolocation</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="center" class="py-3 px-5">
                        <p class="text-body-1 font-weight-light text-center">For additional security, you can limit the locations from which you can recover access to your account.</p>
                        <p class="text-body-1 font-weight-light text-center" v-if="!geolocationList || geolocationList.length === 0">
                            <font-awesome-icon :icon="['fas','exclamation-triangle']" style="color: #FFA000;"/> <!-- #FFA000 is amber darken-2 -->
                            <span class="ml-2">Currently access recovery is allowed from anywhere. Tap the plus button below to add your first access recovery location.</span>
                        </p>
                        <p class="text-body-1 font-weight-light text-center" v-if="geolocationList && geolocationList.length > 0">Access recovery is limited to the locations show below.</p>
                    </v-row>
                    <v-row justify="center" class="pb-3" v-if="geolocationList && geolocationList.length > 0">
                        <div id="map" style="width: 100%; height: 400px;"></div>
                    </v-row>
                    <v-row justify="center" class="pt-3" v-if="geolocationList && geolocationList.length > 0">
                        <v-col cols="8">
                            <v-row justify="start">
                                <v-select
                                    :items="geolocationList"
                                    v-model="geolocation"
                                    label="Safe reset locations"
                                    outlined
                                    @change="setMapCenter()"
                                    return-object
                                    item-value=id
                                    item-text=label
                                ></v-select>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row justify="end" class="pt-2">
                                <v-btn elevation="4" outlined color="red" @click="deleteGeolocation()">Delete</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="pb-2">
                            <v-dialog v-model="dialogCreateGeolocation" max-width="1400">
                                <template #activator="{ on }">
                                <v-btn fab elevation="6" outlined color="blue" @click="addGeolocationButton()" v-on="on">
                                        <font-awesome-icon icon="plus" fixed-width style="font-size: 20px;"/>
                                    </v-btn>
                                </template>
                                <v-card elevation="12" class="pb-4 px-10">
                                    <v-card-title class="py-4">
                                        <v-row justify="center">
                                            <span class="text-h5 blue--text">Add a designated access recovery location</span>
                                            <span class="pr-5" style="position: absolute; right: 0;">
                                                <v-btn icon color="grey" href="#" @click="dialogCreateGeolocation = false">
                                                    <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                                </v-btn>
                                            </span>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider class="mx-5"></v-divider>
                                    <v-row justify="center" class="py-4">
                                        <p class="font-weight-light text-body-1 text-center" v-show="isMapGeometryTypeCircle">Navigate to a location on the map, or search for an address, then tap once to select a center point and again to form a circle.</p>
                                        <p class="font-weight-light text-body-1 text-center" v-show="isMapGeometryTypePolygon">Navigate to a location on the map, or search for an address, then tap multiple times to form a complete polygon.</p>
                                    </v-row>
                                    <v-row justify="center" :class="{'pb-4':true, 'px-4':$vuetify.breakpoint.xsOnly}">
                                        <div id="drawable-map" style="width: 100%; height: 400px;">
                                            <div id="popup" class="ol-popup">
                                                <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                                            <div id="popup-content"></div>
                                            </div>
                                        </div>
                                    </v-row>
                                    <v-row justify="center" class="px-4" v-show="isGeometryTypeEnabled">
                                        <v-select :items="mapGeometryTypeList" v-model="mapGeometryType" label="Geometry Type" outlined @change="changeGeometryType()"></v-select>
                                    </v-row>
                                    <!-- <v-row justify="center" class="px-4">
                                        <v-text-field
                                            v-model=address
                                            label="Address (optional)"
                                            color="blue"
                                            outlined
                                            ref="addressRef"
                                        ></v-text-field>
                                    </v-row> -->
                                    <v-row justify="center" class="px-4">
                                        <v-text-field
                                            v-model=label
                                            label="Nickname for this location (home, office, school, etc.)"
                                            color="blue"
                                            outlined
                                            ref="labelRef"
                                            required
                                            :rules="labelRules"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row class="pt-4 pb-2 px-12" justify="center">
                                        <v-btn class="blue white--text" elevation="6" :disabled="!isCreateGeolocationComplete" @click="createGeolocation">Create</v-btn>
                                    </v-row>
                                </v-card>
                            </v-dialog>
                        </v-row>
                </v-card>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { compact } from '@/sdk/input';
import loginshield from '@/client';
import AppLayout from '@/components/AppLayout.vue';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Draw from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import Projection from 'ol/proj/Projection';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import Geocoder from 'ol-geocoder';
import 'ol-geocoder/dist/ol-geocoder.min.css';

// shape type constants used by the authentication service
const TC_CIRCLE = 'circle';
const TC_POLYGON = 'polygon';

// shape type constants used by the open layers library
const OL_NONE = 'None';
const OL_CIRCLE = 'Circle';
const OL_POLYGON = 'Polygon';

export default {
    data() {
        return {
            snackbarGeolocationCreated: false,
            snackbarGeolocationCreateFailed: false,
            snackbarGeolocationDeleted: false,
            snackbarGeolocationDeleteFailed: false,
            dialogCreateGeolocation: false,
            isLoaded: false,
            isGeometryTypeEnabled: false,
            geolocation: null,
            geolocationList: [],
            mapGeometryType: OL_CIRCLE,
            map: null,
            view: null,
            projection: null,
            vectorSource: null,
            draw: null,
            vector: null,
            drawableMap: null,
            drawableVectorSource: null,
            drawableView: null,
            // address: null,
            label: null,
            isDrawableMapCreated: false,
            pin: null,
            pinRules: [
                (v) => (v && compact(v).length > 3) || 'Enter PIN',
            ],
            mapGeometryTypeList: [
                { text: 'Circle', value: OL_CIRCLE },
                { text: 'Polygon', value: OL_POLYGON },
            ],
            labelRules: [
                (v) => (v && compact(v).length > 0) || 'Enter label',
            ],
        };
    },

    components: {
        AppLayout,
    },

    computed: {
        isPinEnabled() {
            return this.pin
            && this.pin.length > 3;
        },
        isCreateGeolocationComplete() {
            if (this.drawableVectorSource) {
                const features = this.drawableVectorSource.getFeatures();
                const lastFeature = features[features.length - 1];
                if (lastFeature && this.label) {
                    return true;
                }
            }
            return false;
        },
        isMapGeometryTypeCircle() {
            return this.mapGeometryType === OL_CIRCLE;
        },
        isMapGeometryTypePolygon() {
            return this.mapGeometryType === OL_POLYGON;
        },
    },

    watch: {
        dialogCreateGeolocation(val) {
            if (!val) {
                this.dialogCreateGeolocation = false;
            }
        },
    },

    created() {
    },

    mounted() {
        console.log('mounted');
        this.init();
    },

    methods: {
        async init() {
            console.log('init');
            try {
                this.$store.commit('loading', { init: true });
                await this.fetchGeolocationList();
                console.log(`init geolocation: ${this.geolocation}`);
                if (this.geolocationList && this.geolocationList.length > 0) {
                    this.geolocation = this.geolocationList[0];
                    console.log(`init geolocation: ${JSON.stringify(this.geolocation)}`);
                }
                setTimeout(() => { this.generateMap(); }, 1);
                this.isLoaded = true;
            } catch (err) {
                console.error(`init failed ${err}`);
            } finally {
                this.$store.commit('loading', { init: false });
            }
        },
        validatePin() {
            if (this.$refs.pinFormRef.validate()) {
                this.recoverWithPin();
            }
        },
        async fetchGeolocationList() {
            console.log('fetchGeolocationList');
            const { list } = await loginshield.userAuthzGeolocation.list();
            console.log(`fetchGeolocationList setting geolocationList: ${JSON.stringify(list)}`);
            if (list && list.length > 0) {
                this.geolocationList = list;
            } else {
                this.geolocationList = null;
            }
        },
        async recoverWithPin() {
            console.log('recoverWithPin');
        },
        async recoverWithGeolocation() {
            console.log('recoverWithGeolocation');
        },
        generateMap() {
            console.log('generateMap');

            this.projection = new Projection({
                code: 'EPSG:4326',
                units: 'degrees',
            });
            this.vectorSource = new VectorSource();

            if (!this.geolocationList || this.geolocationList.length === 0) {
                console.log('generateMap: geolocationList is null or empty');
                return;
            }

            const geolocationItem = this.geolocationList[0];
            if (!geolocationItem.shape || !geolocationItem.shape.type) {
                console.log('generateMap: invalid geolocation item');
                return;
            }

            let feature = null;

            switch (geolocationItem.shape.type) {
            case TC_CIRCLE: {
                // OL stores points as [x,y] pairs which is [lng,lat]
                // convert radius back to degrees
                const metersPerUnit = this.projection.getMetersPerUnit();
                const radiusInDegrees = geolocationItem.shape.radius / metersPerUnit;
                const circle = new Circle([geolocationItem.shape.center.lng, geolocationItem.shape.center.lat], radiusInDegrees);
                feature = new Feature(circle);
                break;
            }
            case TC_POLYGON: {
                // Convert back to OpenLayers format
                const coordList = [];
                // console.log(`generateMap outline: ${JSON.stringify(this.geolocation.shape.outline)}`);
                for (let i = 0; i < geolocationItem.shape.outline.length; i += 1) {
                    const coord = [];
                    // OL stores points as [x,y] pairs which is [lng,lat]
                    coord[0] = geolocationItem.shape.outline[i].lng;
                    coord[1] = geolocationItem.shape.outline[i].lat;
                    coordList.push(coord);
                }
                const outerCoordList = [];
                outerCoordList.push(coordList);
                // console.log(`generateMap outerCoordList: ${JSON.stringify(outerCoordList)}`);
                const polygon = new Polygon(outerCoordList);
                feature = new Feature(polygon);
                break;
            }
            default:
                console.log(`generateMap: invalid geolocation type ${geolocationItem.shape.type}`);
                return;
            }

            // Add the feature
            this.vectorSource.addFeature(feature);

            // Create vector layer attached to the vector source
            const vectorLayer = new VectorLayer({
                source: this.vectorSource,
            });

            // Create map and view
            switch (geolocationItem.shape.type) {
            case TC_CIRCLE:
                this.view = new View({
                    maxZoom: 20,
                    projection: this.projection,
                });
                break;
            case TC_POLYGON:
                this.view = new View({
                    maxZoom: 20,
                    projection: this.projection,
                });
                break;
            default:
                console.log(`generateMap: invalid geolocation type ${geolocationItem.shape.type}`);
                return;
            }

            const raster = new TileLayer({
                source: new OSM(),
            });
            this.map = new Map({
                layers: [raster],
                target: 'map',
                view: this.view,
            });

            // Zoom map to fit feature
            const extent = feature.getGeometry().getExtent();
            this.map.getView().fit(extent, { padding: [10, 10, 10, 10] });

            // Add the vector layer to the map
            this.map.addLayer(vectorLayer);
        },
        generateDrawableMap() {
            console.log('generateDrawableMap');

            this.drawableVectorSource = new VectorSource({ wrapX: false });

            this.drawableView = new View({
                center: [-100, 30],
                zoom: 4,
                maxZoom: 20,
                projection: this.projection,
            });

            const vectorLayer = new VectorLayer({
                source: this.drawableVectorSource,
            });

            const raster = new TileLayer({
                source: new OSM(),
            });

            this.drawableMap = new Map({
                layers: [raster, vectorLayer],
                target: 'drawable-map',
                view: this.drawableView,
            });
            this.addInteraction();

            // Add address lookup
            const geocoder = new Geocoder('nominatim', {
                provider: 'osm',
                lang: 'en',
                placeholder: 'Search for an address...',
                autoComplete: true,
                limit: 5,
                keepOpen: true,
            });
            this.drawableMap.addControl(geocoder);
            // Listen when an address is chosen
            geocoder.on('addresschosen', (evt) => {
                console.log(`generateDrawableMap address chosen, coordinate: ${evt.coordinate}`);
                setTimeout(() => {
                    this.drawableView.animate({
                        center: [evt.coordinate[0], evt.coordinate[1]],
                        duration: 1500,
                        zoom: 17,
                    });
                }, 1);
            });

            this.isDrawableMapCreated = true;
        },
        addInteraction() {
            console.log(`addInteraction mapGeometryType: ${this.mapGeometryType}`);
            if (this.mapGeometryType !== OL_NONE) {
                this.draw = new Draw({
                    source: this.drawableVectorSource,
                    type: this.mapGeometryType,
                });
                // Only allow one feature to be drawn at a time
                this.draw.on('drawstart', () => {
                    // remove old feature
                    const features = this.drawableVectorSource.getFeatures();
                    if (features.length > 0) {
                        const firstFeature = features[0];
                        this.drawableVectorSource.removeFeature(firstFeature);
                    }
                    // Remove marker
                    // this.drawableVectorSource.removeFeature(evt.feature);
                });
                this.drawableMap.addInteraction(this.draw);
            }
        },
        setMapCenter() {
            console.log(`setMapCenter geolocation: ${JSON.stringify(this.geolocation)}`);
            // Remove any features
            if (this.vectorSource) {
                const features = this.vectorSource.getFeatures();
                if (features.length > 0) {
                    const firstFeature = features[0];
                    this.vectorSource.removeFeature(firstFeature);
                }
            }
            // Create feature with polygon.
            let feature = null;
            switch (this.geolocation.shape.type) {
            case TC_CIRCLE: {
                // OL stores points as [x,y] pairs which is [lng,lat]
                // convert radius back to degrees
                const metersPerUnit = this.projection.getMetersPerUnit();
                const radiusInDegrees = this.geolocation.shape.radius / metersPerUnit;
                const circle = new Circle([this.geolocation.shape.center.lng, this.geolocation.shape.center.lat], radiusInDegrees);
                feature = new Feature(circle);
                break;
            }
            case TC_POLYGON: {
                // Convert back to OpenLayers format
                const coordList = [];
                for (let i = 0; i < this.geolocation.shape.outline.length; i += 1) {
                    const coord = [];
                    // OL stores points as [x,y] pairs which is [lng,lat]
                    coord[0] = this.geolocation.shape.outline[i].lng;
                    coord[1] = this.geolocation.shape.outline[i].lat;
                    coordList.push(coord);
                }
                const outerCoordList = [];
                outerCoordList.push(coordList);
                // console.log(`setMapCenter outerCoordList: ${JSON.stringify(outerCoordList)}`);
                const polygon = new Polygon(outerCoordList);
                feature = new Feature(polygon);
                break;
            }
            default:
                console.log(`setMapCenter: invalid geolocation type ${this.geolocation.shape.type}`);
                return;
            }

            // Add the feature to vectorSource.
            this.vectorSource.addFeature(feature);

            // Move map center to geolocation
            switch (this.geolocation.shape.type) {
            case TC_CIRCLE:
                setTimeout(() => {
                    this.view.animate({
                        // center: [this.geolocation.shape.center.lng, this.geolocation.shape.center.lat],
                        duration: 750,
                    });
                }, 1);
                break;
            case TC_POLYGON:
                setTimeout(() => {
                    // const coords = feature.getGeometry().getInteriorPoint().getCoordinates();
                    this.view.animate({
                        // center: [coords[0], coords[1]],
                        duration: 750,
                    });
                }, 1);
                break;
            default:
                console.log(`setMapCenter setTimeout: invalid geolocation type ${this.geolocation.shape.type}`);
            }

            // Zoom map to fit feature
            const extent = feature.getGeometry().getExtent();
            this.map.getView().fit(extent, { padding: [10, 10, 10, 10] });
        },
        changeGeometryType() {
            this.drawableMap.removeInteraction(this.draw);
            this.addInteraction();
        },
        addGeolocationButton() {
            // setTimeout(() => { this.$refs.addressRef.reset(); }, 1);
            setTimeout(() => { this.$refs.labelRef.reset(); this.$refs.labelRef.resetValidation(); }, 1);
            this.dialogCreateGeolocation = true;
            // Create drawable map if it hasn't been created yet
            if (!this.isDrawableMapCreated) {
                setTimeout(() => { this.generateDrawableMap(); }, 1);
            }
            // Remove drawings from map
            if (this.draw) {
                const features = this.drawableVectorSource.getFeatures();
                const lastFeature = features[features.length - 1];
                if (lastFeature) {
                    this.drawableVectorSource.removeFeature(lastFeature);
                }
            }
        },
        async createGeolocation() {
            const features = this.drawableVectorSource.getFeatures();
            const feature = features[features.length - 1];
            // console.log(`createGeolocation feature: ${JSON.stringify(feature)}`);
            // console.log(`createGeolocation feature.getGeometry(): ${JSON.stringify(feature.getGeometry())}`);
            let center = null;
            let radius = null;
            const outline = [];
            let shape = null;

            // Add new geolocation to database
            switch (this.mapGeometryType) {
            case OL_CIRCLE: {
                center = feature.getGeometry().getCenter();
                const radiusInDegrees = feature.getGeometry().getRadius();
                const metersPerUnit = this.projection.getMetersPerUnit();
                radius = radiusInDegrees * metersPerUnit;

                // OL stores points as [x,y] pairs which is [lng,lat]
                shape = { type: TC_CIRCLE, center: { lat: center[1], lng: center[0] }, radius };
                // console.log(`createGeolocation center: ${JSON.stringify(center)}, radius: ${radius}`);
                break;
            }
            case OL_POLYGON: {
                const coords = feature.getGeometry().getCoordinates();
                // console.log(`createGeolocation coords: ${JSON.stringify(coords)}`);
                // Convert to service format (array of Points)
                for (let i = 0; i < coords.length; i += 1) {
                    for (let j = 0; j < coords[i].length; j += 1) {
                        // OL stores points as [x,y] pairs which is [lng,lat]
                        const point = { lat: coords[i][j][1], lng: coords[i][j][0] };
                        outline.push(point);
                    }
                }
                // console.log(`createGeolocation outline: ${JSON.stringify(outline)}`);
                shape = { type: TC_POLYGON, outline };
                break;
            }
            default:
                console.error(`createGolocation: invalid mapGeometryType value: ${this.mapGeometryType}`);
                this.snackbarGeolocationCreateFailed = true;
                return;
            }

            const request = { label: this.label, shape };
            console.log(`createGeolocation request: ${JSON.stringify(request)}`);
            const response = await loginshield.userAuthzGeolocation.create(request);
            console.log(`createGeolocation response: ${JSON.stringify(response)}`);
            if (response.isCreated) {
                this.dialogCreateGeolocation = false;
                await this.fetchGeolocationList();
                this.geolocation = {
                    id: response.id, label: request.label, shape: request.shape,
                };
                // If this is the first geolocation added, generate the readonly map
                if (this.geolocationList && this.geolocationList.length === 1) {
                    this.generateMap();
                }
                this.setMapCenter();
                this.snackbarGeolocationCreated = true;
            } else {
                this.snackbarGeolocationCreateFailed = true;
            }
        },
        async deleteGeolocation() {
            console.log(`deleteGeolocation id: ${this.geolocation.id}`);
            // TODO: delete geolocation from database
            const response = await loginshield.userAuthzGeolocation.delete(this.geolocation.id);
            console.log(`deleteGeolocation response: ${JSON.stringify(response)}`);
            if (response.isDeleted) {
                await this.fetchGeolocationList();
                // Set map to first geolocation or blank map if empty
                // console.log(`deleteGeolocation geolocation deleted, list: ${JSON.stringify(this.geolocationList)}`);
                if (this.geolocationList && this.geolocationList.length > 0) {
                    this.geolocation = this.geolocationList[0];
                    this.setMapCenter();
                } else if (this.vectorSource) {
                    // Remove any features and reset map position
                    const features = this.vectorSource.getFeatures();
                    if (features.length > 0) {
                        const firstFeature = features[0];
                        this.vectorSource.removeFeature(firstFeature);
                    }
                    setTimeout(() => {
                        this.view.animate({
                            center: [0, 0],
                            duration: 750,
                            zoom: 2,
                        });
                    }, 1);
                }
                this.snackbarGeolocationDeleted = true;
            } else {
                this.snackbarGeolocationDeleteFailed = true;
            }
        },
    },
};
</script>
